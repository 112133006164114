@use '@angular/material' as mat;
$agilicus-blue: #0057b8;
$agilicus-green: #57b800;
$agilicus-red: #b80057;
$agilicus-orange: #e69138;
$agilicus-purple: #674ea7;

// http://mcg.mbitson.com (https://github.com/mbitson/mcg)
$mat-agilicus-blue: (
  50: #e0ebf6,
  100: #b3cdea,
  200: #80abdc,
  300: #4d89cd,
  400: #2670c3,
  500: #0057b8,
  600: #004fb1,
  700: #0046a8,
  800: #003ca0,
  900: #002c91,
  A100: #bdcbff,
  A200: #8aa3ff,
  A400: #577cff,
  A700: #3d68ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$mat-agilicus-red: (
  50: #f6e0eb,
  100: #eab3cd,
  200: #dc80ab,
  300: #cd4d89,
  400: #c32670,
  500: #b80057,
  600: #b1004f,
  700: #a80046,
  800: #a0003c,
  900: #91002c,
  A100: #ffbdcb,
  A200: #ff8aa3,
  A400: #ff577c,
  A700: #ff3d68,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$mat-agilicus-green: (
  50: #ebf6e0,
  100: #cdeab3,
  200: #abdc80,
  300: #89cd4d,
  400: #70c326,
  500: #57b800,
  600: #4fb100,
  700: #46a800,
  800: #3ca000,
  900: #2c9100,
  A100: #cbffbd,
  A200: #a3ff8a,
  A400: #7cff57,
  A700: #68ff3d,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$mat-agilicus-orange: (
  50: #fcf2e7,
  100: #f8dec3,
  200: #f3c89c,
  300: #eeb274,
  400: #eaa256,
  500: #e69138,
  600: #e38932,
  700: #df7e2b,
  800: #db7424,
  900: #d56217,
  A100: #ffffff,
  A200: #ffe3d4,
  A400: #ffc3a1,
  A700: #ffb287,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$mat-agilicus-purple: (
  50: #edeaf4,
  100: #d1cae5,
  200: #b3a7d3,
  300: #9583c1,
  400: #7e69b4,
  500: #674ea7,
  600: #5f479f,
  700: #543d96,
  800: #4a358c,
  900: #39257c,
  A100: #cbbdff,
  A200: #a28aff,
  A400: #7a57ff,
  A700: #663dff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$profile-primary: mat.m2-define-palette($mat-agilicus-blue);
$profile-accent: mat.m2-define-palette($mat-agilicus-green, A200, A100, A400);

$profile-warn: mat.m2-define-palette($mat-agilicus-red);

// Create the theme object (a Sass map containing all of the palettes).
$profile-theme: mat.m2-define-light-theme($profile-primary, $profile-accent, $profile-warn);

h1 {
  font-family: Canada1500, Roboto, 'Helvetica Neue', sans-serif;
}

body {
  font-family: 'Proxima Nova', Roboto, 'Helvetica Neue', sans-serif;
}

.mat-mdc-tooltip {
  font-size: 14px;
  min-width: fit-content;
}
