@use '@angular/material' as mat;

@mixin profile-app-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .invalid-feedback {
    color: mat.m2-get-color-from-palette($warn);
  }
  mat-sidenav-container {
    mat-toolbar {
      button {
        &.active {
          color: mat.m2-get-color-from-palette($accent, default-contrast);
          background-color: mat.m2-get-color-from-palette($accent);
        }
      }

      .link {
        color: mat.m2-get-color-from-palette($primary, default-contrast);
      }
    }

    mat-sidenav {
      .mat-list-item {
        &.active {
          color: mat.m2-get-color-from-palette($accent, default-contrast);
          background-color: mat.m2-get-color-from-palette($accent) !important;

          &:hover {
            background-color: mat.m2-get-color-from-palette($accent, darker) !important;
          }
        }
      }
      min-width: 260px;
    }

    .footer {
      color: mat.m2-get-color-from-palette($primary, lighter);
      background-color: mat.m2-get-color-from-palette($primary, darker);

      .links,
      .signature {
        a {
          color: mat.m2-get-color-from-palette($primary, lighter);
          &:hover {
            color: mat.m2-get-color-from-palette($accent);
          }
        }
      }
    }
  }

  .error-notification-overlay {
    color: mat.m2-get-color-from-palette($warn, default-contrast);
    background-color: mat.m2-get-color-from-palette($warn);
    --mdc-snackbar-supporting-text-color: mat.m2-get-color-from-palette($warn, default-contrast) !important;
    --mdc-snackbar-container-color: mat.m2-get-color-from-palette($warn) !important;
  }

  .info-notification-overlay {
    color: mat.m2-get-color-from-palette($accent, default-contrast);
    background-color: mat.m2-get-color-from-palette($accent);
    --mdc-snackbar-supporting-text-color: mat.m2-get-color-from-palette($accent, default-contrast) !important;
    --mdc-snackbar-container-color: mat.m2-get-color-from-palette($accent) !important;
  }

  .success-notification-overlay {
    color: mat.m2-get-color-from-palette($success-colors, default-contrast);
    background-color: mat.m2-get-color-from-palette($success-colors);
    --mdc-snackbar-supporting-text-color: mat.m2-get-color-from-palette($success-colors, default-contrast) !important;
    --mdc-snackbar-container-color: mat.m2-get-color-from-palette($success-colors) !important;
  }

  .warning-notification-overlay {
    color: mat.m2-get-color-from-palette($warning-colors, default-contrast);
    background-color: mat.m2-get-color-from-palette($warning-colors);
    --mdc-snackbar-supporting-text-color: mat.m2-get-color-from-palette($warning-colors, default-contrast) !important;
    --mdc-snackbar-container-color: mat.m2-get-color-from-palette($warning-colors) !important;
  }

  .mat-tooltip {
    font-size: 14px;
    min-width: fit-content;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: inherit;
  }

  .mat-primary .mat-pseudo-checkbox-checked,
  .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: mat.m2-get-color-from-palette($accent);
  }

  .right .mat-list-item-content {
    display: inline-block;
    width: 49%;
  }

  .right .mat-list-item-content .mat-form-field {
    width: 92%;
  }

  .mat-tab-group {
    font-family: 'Proxima Nova', Roboto, 'Helvetica Neue', sans-serif;
  }

  .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    background-color: mat.m2-get-color-from-palette($primary);
  }

  .owl-dt-container-buttons {
    color: mat.m2-get-color-from-palette($primary);
  }

  tr.mat-row:hover {
    background: rgb(230, 230, 230);
  }

  tr.mat-row:focus {
    outline: none;
    background: rgb(230, 230, 230);
  }

  tr.mat-row:focus-within {
    background: rgb(230, 230, 230);
  }

  .mat-chip.mat-standard-chip {
    background-color: mat.m2-get-color-from-palette($primary);
    color: white;
    min-height: 26px !important;
  }

  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: white;
    opacity: 1;
  }

  .mat-snack-bar-container {
    max-width: 50vw !important;
  }

  // Allows the autocomplete dropdowns to expand to fit the content
  .cdk-overlay-pane {
    min-width: fit-content;
    max-width: none;
  }
}
