/* You can add global styles to this file, and also import other style files */

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@use '@angular/material' as mat;
@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

@import 'themes/default.scss';
@import './no-vnc/app/styles/base.css';
@import './no-vnc/app/styles/input.css';
@import 'app/app.component.scss-theme';
@import 'styles-variables';

@mixin custom-components-theme($theme) {
  @include profile-app-component-theme($theme);
}
@include custom-components-theme($profile-theme);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Proxima Nova',
  $headline-5: mat.m2-define-typography-level(32px, 48px, 700),
  $body-2: mat.m2-define-typography-level(16px, 24px, 500),
);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, Helvetica Neue, sans-serif;
}

.vnc-dialog {
  .mat-mdc-dialog-container {
    padding: 0px !important;
  }
}

.mfa-dialog {
  .theme-body {
    position: relative !important;
  }
}

.terminal.xterm {
  padding: 0px;
  inset: 0px;
  width: auto;
  height: auto;
  z-index: 255;
}

.ssh-main-container .terminal.xterm {
  position: fixed;
}

.ssh-container-dialog {
  .resize-box {
    width: 100% !important;
    height: 100% !important;
  }
  .terminal.xterm {
    position: absolute;
  }
}

.mdc-dialog__title {
  margin: 0 0 20px !important;
  font: 500 20px/32px Roboto, Helvetica Neue, sans-serif !important;
  letter-spacing: normal !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
}

.xterm .xterm-viewport {
  width: 100% !important;
}

.xterm .composition-view.active {
  background: rgb(45, 46, 44) !important;
}

.xterm-rows {
  font-family: 'Cascadia Code', Menlo, monospace !important;
}

.mat-mdc-snack-bar-container {
  z-index: 1000;
}

.mat-drawer-content,
.mat-drawer.mat-drawer-side {
  z-index: -2 !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-mdc-tab-labels,
.mat-mdc-raised-button {
  font-family: Roboto, Helvetica Neue, sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

mat-sidenav {
  width: 220px;
}

.rdp-options-config {
  min-height: 75vh !important;
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
  .mat-form-field-infix {
    padding: 0px !important;
  }
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-label {
    margin-left: 5px;
  }
  input {
    margin-top: 5px !important;
  }
  .mat-mdc-tab-body-wrapper .mat-mdc-tab-body:nth-child(2) .mat-mdc-tab-body-content {
    overflow: hidden;
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: Roboto, Helvetica Neue, sans-serif !important;
  letter-spacing: normal !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #000000de !important;
  line-height: normal !important;
}

.mat-mdc-menu-panel {
  transform-origin: center center !important;
}

.mdc-menu-surface {
  max-height: calc(100vh - 32px) !important;
}

.filter-container {
  input {
    background: none;
  }

  mat-form-field,
  .mat-form-field,
  .mat-mdc-form-field,
  .mat-mdc-text-field-wrapper,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: #fafafa !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .mat-mdc-form-field-infix {
    min-height: unset;
  }

  input:not([type]):focus {
    box-shadow: none;
    border-color: none;
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($profile-theme);

.ng-terminal {
  font-family: Roboto, monospace, sans-serif;
}
